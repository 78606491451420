<template>
  <div class="liquidacion mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>
            <h3>Liquidación de profesionales</h3>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <h2>Periodo de liquidación</h2>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_ini"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_ini"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @change="loadData()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @change="loadData()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <v-select
                  rounded
                  outlined
                  dense
                  v-model="profesional"
                  color="primary"
                  :items="profesionales"
                  item-color="primary"
                  item-text="nombre"
                  :menu-props="{ maxHeight: '400' }"
                  label="Profesionales"
                  return-object
                ></v-select>
              </v-col>
              <v-col cols="12" md="4" sm="6" v-if="profesional">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <v-row align="center" no-gutters>
                      <v-col>
                        <strong>
                          {{ profesional.nombre }}
                        </strong>
                        <br />
                        <strong> Cc: {{ profesional.cedula }} </strong>
                        <br />
                        <strong> Apodo: {{ profesional.apodo }} </strong>
                      </v-col>
                      <v-col align="right">
                        <v-avatar>
                          <img
                            :src="
                              $store.state.centro.route +
                                'profesionales/' +
                                profesional.foto
                            "
                          />
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-row>
                          <v-col>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="black"
                                  dark
                                  @click="print()"
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  <v-icon left>mdi-printer</v-icon>
                                  POS
                                </v-btn>
                              </template>
                              <span>Imprimir</span>
                            </v-tooltip>
                          </v-col>
                          <v-col>
                            <v-tooltip top>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="black"
                                  dark
                                  @click="downloadExcle()"
                                  v-on="on"
                                  v-bind="attrs"
                                >
                                  <v-icon left>mdi-download</v-icon>
                                  Generar Excel
                                </v-btn>
                              </template>
                              <span>Generar Excel</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="2" sm="2"> </v-col>
              <v-col cols="12" md="12" sm="12" v-if="profesional" class="mt-4">
                <h2>Servicios realizados</h2>
                <v-simple-table dense class="mt-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Servicios</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in ventas" :key="index">
                        <td>
                          {{ moment(item.fecha).format("ll") }}
                        </td>
                        <td>
                          {{ item.servicios }}
                        </td>
                        <td>
                          {{ item.total | currency }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th class="active">Total</th>
                        <th class="active">
                          {{ totalizacion.servicios }}
                        </th>
                        <th class="active">
                          {{ totalizacion.total_servicios | currency }}
                        </th>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="profesional" class="mt-4">
                <h2>Productos vendidos</h2>
                <v-simple-table dense class="mt-4">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Subtotal</th>
                        <th>Iva</th>
                        <th>Total</th>
                        <th>% Comision</th>
                        <th>Comision</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in productos" :key="index">
                        <td>
                          {{ item.salida }}
                        </td>
                        <td>
                          {{ item.cantidad }}
                        </td>
                        <td>
                          {{ item.subtotal | currency }}
                        </td>
                        <td>
                          {{ item.iva | currency }}
                        </td>
                        <td>
                          {{ item.total | currency }}
                        </td>
                        <td>
                          <v-text-field
                            style="width: 140px"
                            type="number"
                            class="mt-4"
                            dense
                            outlined
                            rounded
                            v-model.number="item.porcentaje"
                            label="Porcentaje"
                            @change="updateComision(item, index)"
                          ></v-text-field>
                        </td>
                        <td>
                          {{ item.comision | currency }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th class="active">Total</th>
                        <th class="active">
                          {{ totalizacion.productos }}
                        </th>
                        <th class="active">
                          {{ totalizacion.subtotal | currency }}
                        </th>
                        <th class="active">
                          {{ totalizacion.iva | currency }}
                        </th>
                        <th class="active">
                          {{ totalizacion.total | currency }}
                        </th>
                        <th class="active"></th>
                        <th class="active">
                          {{ totalizacion.comision | currency }}
                        </th>
                      </tr>
                    </tfoot>
                  </template>
                </v-simple-table>
              </v-col>
              <v-col cols="12" md="8" sm="6" v-if="profesional" class="mt-4">
                <h2>Totalización</h2>
                <v-simple-table dense class="mt-4">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th colspan="4">Total comisión de servicios</th>
                        <th colspan="1">
                          {{ totalizacion.total_servicios | currency }}
                        </th>
                      </tr>
                      <tr>
                        <th colspan="4">Total comisión de productos</th>
                        <th colspan="1">
                          {{ totalizacion.comision | currency }}
                        </th>
                      </tr>
                      <tr>
                        <th colspan="2">Total propina</th>
                        <th colspan="2" style="text-align:right">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                small
                                text
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                                @click="dialog = true"
                              >
                                {{ total_propina | currency }} de
                                {{ propinas.length }} facturas
                              </v-btn>
                            </template>
                            <span>Facturas con propina</span>
                          </v-tooltip>
                        </th>
                        <th colspan="1">
                          {{ total_propina | currency }}
                        </th>
                      </tr>
                      <tr>
                        <th>Pagos datafono</th>
                        <th>{{ total_datafono | currency }}</th>
                        <th>Impuesto datafono</th>
                        <th>
                          <v-text-field
                            style="width: 140px"
                            type="number"
                            class="mt-4"
                            dense
                            outlined
                            rounded
                            label="Impuesto"
                            v-model.number="impuesto.porcentaje"
                            @change="valueImpuesto"
                          ></v-text-field>
                        </th>
                        <th>
                          <span style="color:red">
                            {{ impuesto.valor | currency }}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th colspan="4">Deducciones</th>
                        <th>
                          <span style="color:red">
                            {{ prestamos | currency }}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th colspan="4">Deducciones por abonos</th>
                        <th>
                          <span style="color:red">
                            {{ deducciones | currency }}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th class="active" colspan="4">Total ingresos netos</th>
                        <th class="active" colspan="1">
                          {{
                            (totalizacion.total_servicios +
                              totalizacion.comision -
                              impuesto.valor +
                              total_propina -
                              prestamos -
                              deducciones)
                              | currency
                          }}
                        </th>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-dialog v-model="dialog" scrollable max-width="800px">
                  <v-card>
                    <v-card-title>
                      Facturas con propina
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :expanded.sync="expanded"
                        dense
                        :items="facturas_propina"
                        show-expand
                      >
                        <template v-slot:item.fecha="{ item }">
                          {{ moment(item.fecha).format("ll") }}
                        </template>
                        <template v-slot:item.folio="{ item }">
                          <router-link :to="'/detallefactura/' + item.id">
                            <span>
                              {{ item.folio | folio }}
                            </span>
                          </router-link>
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ item.total | currency }}
                        </template>
                        <template v-slot:item.propina="{ item }">
                          {{ item.propina | currency }}
                        </template>
                        <template v-slot:expanded-item="{ headers, item }">
                          <td
                            :colspan="headers.length"
                            style="padding:0 !important"
                          >
                            <v-simple-table
                              dense
                              style="background:transparent !important"
                            >
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th>Profesional</th>
                                    <th>Propina</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(item_pr,
                                    index_pr) in item.profesionales"
                                    :key="index_pr"
                                  >
                                    <td>
                                      {{ item_pr.profesional }}
                                    </td>
                                    <td>
                                      <v-text-field
                                        v-if="
                                          item_pr.profesional_id ==
                                            profesional.id
                                        "
                                        style="width: 140px"
                                        type="number"
                                        class="mt-4"
                                        dense
                                        outlined
                                        rounded
                                        label="Propina"
                                        min="0"
                                        v-model.number="item_pr.propina"
                                      ></v-text-field>
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </td>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="4" sm="6" v-if="profesional" class="mt-4">
                <h2>Prestamos desc pendientes</h2>
                <v-simple-table dense class="mt-4">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th class="active">Saldo</th>
                        <th class="active">
                          {{ prestamos_desc | currency }}
                        </th>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    date_ini: moment().format("YYYY-MM-DD"),
    date_fin: moment().format("YYYY-MM-DD"),
    profesionales: [],
    profesional: null,
    ventas: [],
    productos: [],
    prestamo: 0,
    descontable: [],
    prestamos: 0,
    propinas: [],
    dialog: false,
    expanded: [],
    headers: [
      {
        text: "Fecha",
        align: "start",
        sortable: false,
        value: "fecha",
      },
      {
        text: "Factura",
        sortable: false,
        value: "folio",
      },
      {
        text: "Total",
        sortable: false,
        value: "total",
      },
      {
        text: "Total propina",
        sortable: false,
        value: "propina",
      },
      { text: "", value: "data-table-expand" },
    ],
    impuesto: {
      valor: 0,
      porcentaje: 0,
    },
    deducciones: 0,
  }),
  methods: {
    loadProfesionales() {
      const body = {
        route: "/profesionales_sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data;
        }
      });
    },
    loadLiquidacion() {
      const body = {
        route: "/liquidacion_profesional",
        params: {
          id: this.profesional.id,
          date: JSON.stringify({ inicio: this.date_ini, fin: this.date_fin }),
        },
      };

      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.ventas = response.data.data.ventas.map((venta) => {
            venta.servicios = parseFloat(venta.servicios);
            venta.total = parseFloat(venta.total);
            return venta;
          });

          this.productos = response.data.data.productos.reduce(
            (grupo, item) => {
              let data = item["producto_id"];
              grupo[data] = grupo[data] || {
                producto_id: 0,
                salida: "",
                cantidad: 0,
                comision: 0,
                porcentaje: 0,
                subtotal: 0,
                iva: 0,
                total: 0,
              };

              grupo[data].producto_id = item.producto_id;
              grupo[data].salida = item.salida;
              grupo[data].cantidad += parseFloat(item.cantidad);
              grupo[data].subtotal += parseFloat(item.subtotal);
              grupo[data].iva += parseFloat(item.iva);
              grupo[data].total += parseFloat(item.total);

              return grupo;
            },
            {}
          );
          this.productos = Object.values(this.productos);

          this.descontable = response.data.data.descontables;
          this.propinas = response.data.data.facturas_propina;
          this.deducciones = response.data.data.abonos;
          this.prestamos = response.data.data.prestamos;
        }
      });
    },
    loadData() {
      if (this.profesional) {
        this.loadLiquidacion();
      }
    },
    updateComision(item, index) {
      item.comision = (item.subtotal * item.porcentaje) / 100;
    },
    valueImpuesto(input) {
      if (input === "") {
        this.impuesto.porcentaje = 0;
      } else {
        this.impuesto.valor =
          (this.total_datafono * this.impuesto.porcentaje) / 100;
      }
    },
    print() {
      let comision = {
        comision: this.totalizacion.comision,
        productos: [],
        impuesto: this.impuesto,
      };
      if (this.totalizacion.comision > 0) {
        let productos = this.productos.filter((producto) => {
          return producto.comision > 0;
        });

        comision.productos = productos.map((producto) => {
          return {
            producto: producto.salida,
            porcentaje: producto.porcentaje,
            comision: producto.comision,
          };
        });
      }
      let route = this.$router.resolve({
        path: `/print_liquidacion/${this.profesional.id}/${this.date_ini}/${
          this.date_fin
        }/${btoa(JSON.stringify(comision))}/${this.total_propina}/${
          this.deducciones
        }/${this.prestamos}`,
      });
      window.open(route.href, "_blank");
    },
    moment(input) {
      return moment(input);
    },
    downloadExcle() {
      const name = `liquidacion_${this.profesional.cedula}`;

      let data = [];

      data.push(
        {
          Columna_A: "Fecha inicial",
          Columna_B: moment(this.date_ini).format("ll"),
        },
        {
          Columna_A: "Fecha final",
          Columna_B: moment(this.date_fin).format("ll"),
        },
        {
          Columna_A: "",
        },
        {
          Columna_A: "Profesional",
          Columna_B: this.profesional.nombre,
        },
        {
          Columna_A: "Cedula",
          Columna_B: this.profesional.cedula,
        },
        {
          Columna_A: "Cedula",
          Columna_B: this.profesional.cedula,
        },
        {
          Columna_A: "",
        },
        {
          Columna_A: "Servicios realizados",
        },
        {
          Columna_A: "Fecha",
          Columna_B: "Servicios",
          Columna_C: "Total",
        }
      );

      this.ventas.forEach((venta) => {
        data.push({
          Columna_A: moment(venta.fecha).format("ll"),
          Columna_B: venta.servicios,
          Columna_C: parseFloat(venta.total),
        });
      });

      data.push(
        {
          Columna_A: "Total",
          Columna_B: this.totalizacion.servicios,
          Columna_C: this.totalizacion.total_servicios,
        },
        {
          Columna_A: "",
        },
        {
          Columna_A: "Productos vendidos",
        },
        {
          Columna_A: "Producto",
          Columna_B: "Cantidad",
          Columna_C: "Subtotal",
          Columna_D: "Iva",
          Columna_E: "Total",
          Columna_F: "% Comision",
          Columna_G: "Comision",
        }
      );

      this.productos.forEach((producto) => {
        data.push({
          Columna_A: producto.salida,
          Columna_B: producto.cantidad,
          Columna_C: producto.subtotal,
          Columna_D: producto.iva,
          Columna_E: producto.total,
          Columna_F: producto.porcentaje,
          Columna_G: producto.comision,
        });
      });

      data.push(
        {
          Columna_A: "Total",
          Columna_B: this.totalizacion.productos,
          Columna_C: this.totalizacion.subtotal,
          Columna_D: this.totalizacion.iva,
          Columna_E: this.totalizacion.total,
          Columna_F: "",
          Columna_G: this.totalizacion.comision,
        },
        {
          Columna_A: "",
        },
        {
          Columna_A: "Totalización",
        },
        {
          Columna_A: "Total comisión de servicios",
          Columna_B: this.totalizacion.total_servicios,
        },
        {
          Columna_A: "Total comisión de productos",
          Columna_B: this.totalizacion.comision,
        },
        {
          Columna_A: "Total propina",
          Columna_B: this.total_propina,
        },
        {
          Columna_A: "Pagos datafono",
          Columna_B: -this.impuesto.valor,
        },
        {
          Columna_A: "Deducciones",
          Columna_B: -this.prestamos,
        },
        {
          Columna_A: "Deducciones por abonos",
          Columna_B: -this.deducciones,
        },
        {
          Columna_A: "Total ingresos netos",
          Columna_B:
            this.totalizacion.total_servicios +
            this.totalizacion.comision -
            this.impuesto.valor +
            this.total_propina -
            this.prestamos -
            this.deducciones,
        },
        {
          Columna_A: "",
        },
        {
          Columna_A: "Prestamos desc pendientes",
        },
        {
          Columna_A: "Saldo",
          Columna_B: this.prestamos_desc,
        }
      );
      
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
  created() {
    this.loadProfesionales();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion", "informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  watch: {
    profesional(val) {
      this.loadLiquidacion();
      return this.profesional;
    },
  },
  computed: {
    total_datafono() {
      return this.ventas.reduce((index, item) => {
        return index + parseFloat(item.datafono);
      }, 0);
    },
    prestamos_desc() {
      return this.descontable.reduce((index, item) => {
        return (
          index +
          parseFloat(item.valor) +
          parseFloat(item.iva) -
          parseFloat(item.abonos)
        );
      }, 0);
    },
    facturas_propina() {
      return this.propinas.map((_propina) => {
        _propina.profesionales = _propina.profesionales.map((profesional) => {
          let data = profesional;
          data = Object.assign({ propina: 0 }, data);
          return data;
        });
        return _propina;
      });
    },
    total_propina() {
      const propinas = this.facturas_propina.map((propina) => {
        return propina.profesionales
          .map((propina_pr) => {
            if (propina_pr.propina == "") {
              propina_pr.propina = 0;
            } else if (propina_pr.propina > propina.propina) {
              propina_pr.propina = propina.propina;
            }
            return propina_pr.propina;
          })
          .reduce((index, item) => {
            return index + parseFloat(item);
          }, 0);
      });
      return propinas.reduce((index, item) => {
        return index + parseFloat(item);
      }, 0);
    },
    totalizacion() {
      return {
        servicios: this.ventas.reduce((index, item) => {
          return index + item.servicios;
        }, 0),
        total_servicios: this.ventas.reduce((index, item) => {
          return index + item.total;
        }, 0),
        productos: this.productos.reduce((index, item) => {
          return index + item.cantidad;
        }, 0),
        subtotal: this.productos.reduce((index, item) => {
          return index + item.subtotal;
        }, 0),
        iva: this.productos.reduce((index, item) => {
          return index + item.iva;
        }, 0),
        total: this.productos.reduce((index, item) => {
          return index + item.total;
        }, 0),
        comision: this.productos.reduce((index, item) => {
          return index + item.comision;
        }, 0),
      };
    },
  },
};
</script>
<style scoped>
.active {
  background: rgba(0, 0, 0, 0.1) !important;
}
</style>
